import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { portfolioTheme } from 'common/theme/portfolio';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/Portfolio/portfolio.style';

import BannerSection from 'containers/Portfolio/Banner';
import PortfolioShowcase from 'containers/Portfolio/PortfolioShowcase';
import ClientsSection from 'containers/Portfolio/Clients';
import CoreTech from 'containers/Portfolio/CoreTech';
import Footer from 'containers/Portfolio/Footer';
import Seo from 'components/seo';

const Index = () => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Seo title="Adam Clark" />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <BannerSection />
          <ClientsSection />
          <PortfolioShowcase />
          <CoreTech />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
