import styled from 'styled-components';

const BannerWrapper = styled.section`
  position: relative;
  background: rgb(2,0,36);
  background: -moz-linear-gradient(149deg, rgba(2,0,36,1) 0%, rgba(2,11,22,1) 50%, rgba(5,24,48,1) 100%);
  background: -webkit-linear-gradient(149deg, rgba(2,0,36,1) 0%, rgba(2,11,22,1) 50%, rgba(5,24,48,1) 100%);
  background: linear-gradient(149deg, rgba(2,0,36,1) 0%, rgba(2,11,22,1) 50%, rgba(5,24,48,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#051830",GradientType=1);
  display: flex;
  align-items: center;
  padding-top: 80px;
  display: flex;
  @media (min-width: 991px) {
    min-height: 100vh;
  }
  
  .social-svg-icon {
    fill: #fff!important;
  }

  .image_area {
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export default BannerWrapper;
