import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Tabs, { TabPane } from 'rc-tabs';
// import TabContent from 'rc-tabs/lib/TabContent';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';
import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,
  PortfolioLink,
  BuiltWith,
  PortfolioMeta,
  MetaItem,
} from './portfolioShowcase.style';
import { PrevButton, NextButton } from '../portfolio.style';
import Button from 'common/components/Button';

const PortfolioShowcase = ({
  sectionWrapper,
  sectionTitle,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
  companyStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        PORTFOLIO_SHOWCASE {
          title
          portfolioItem {
            title
            description
            link
            view
            love
            feedback
            featuredIn
            featuredLink
            website
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `);

  //Carousel Options
  const carouselOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
    animationDuration: 900,
  };

  return (
    <Box {...sectionWrapper} as="section" id="portfolio_section">
      <Container noGutter mobileGutter width="1200px">

        <Text
          content="Current Projects"
          as="h2"
          { ...sectionTitle }
        />

        <PortfolioShowcaseWrapper>
          {Data.portfolioJson.PORTFOLIO_SHOWCASE.map((tabItem, index) => (
            <GlideCarousel
              carouselSelector={`portfolio-showcase-carousel-${index + 1}`}
              options={carouselOptions}
              prevButton={
                <PrevButton>
                  <span />
                </PrevButton>
              }
              nextButton={
                <NextButton>
                  <span />
                </NextButton>
              }
            >
              <>
                {tabItem.portfolioItem.map((portfolioItem, index) => (
                  <GlideSlide key={`PortfolioShowcaseItem-${index}`}>
                    <PortfolioShowcaseItem>
                      <Box {...portfolioImage}>
                        <GatsbyImage
                          src={
                            (portfolioItem.image !== null) | undefined
                              ? portfolioItem.image.childImageSharp
                                .gatsbyImageData
                              : {}
                          }
                          alt={`PortfolioImage-${index + 1}`}
                        />
                      </Box>
                      <Box {...portfolioDetails}>
                        <Heading
                          content={portfolioItem.featuredIn}
                          {...companyStyle}
                        />
                        <Heading
                          content={portfolioItem.title}
                          {...titleStyle}
                        />
                        <Text
                          content={portfolioItem.description}
                          {...detailsStyle}
                        />
                        {portfolioItem.buildWith ? (
                          <BuiltWith>
                            {portfolioItem.buildWith.map((item, index) => (
                              <span key={`buildWith-item-${index}`}>
                                    {item.content}
                                  </span>
                            ))}
                          </BuiltWith>
                        ) : (
                          ''
                        )}
                      </Box>


                    </PortfolioShowcaseItem>
                  </GlideSlide>
                ))}
              </>
            </GlideCarousel>
          ))}
        </PortfolioShowcaseWrapper>
      </Container>
    </Box>
  );
};

PortfolioShowcase.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

PortfolioShowcase.defaultProps = {
  sectionTitle: {
    color: '#fff',
    fontSize: '3em',
    borderBottom: '1px solid #fff',
    pb: '0.2em',
    mb: '2em',
    mt: '2em',
  },
  sectionWrapper: {
    pt: '2em',
    pb: '10em',
    backgroundColor: '#030b16',
  },
  secTitleWrapper: {
    width: ['100%', '100%', '60%', '50%', '50%'],
    mb: ['50px', '65px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '600',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#fff',
    lineHeight: '1.5',
    mb: '0',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  companyStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#fff',
    mb: '0',
  },
  titleStyle: {
    fontSize: ['18px', '18px', '20px', '35px', '35px'],
    fontWeight: '400',
    color: '#fff',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#fff',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default PortfolioShowcase;
