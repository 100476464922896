
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import { ClientsImage } from './clients.style';

const ClientsSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  row,
  secTitleNext,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        TECHNOLOGIES {
          title
          group
          image {
            publicURL
          }
        }
      }
    }
  `);

  const groups = [
    { name: 'Platforms & Providers', key: 'platform' },
    { name: 'Frameworks', key: 'framework' },
    { name: 'Data & MQ', key: 'data' },
  ];

  return (
    <Box style={{ overflow: 'hidden' }} {...sectionWrapper} as="section">
      <Container noGutter width="1200px">

        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Core Technologies" />
        </Box>

        {
          groups.map((group) => (
            <div style={{ float: 'left' }}>
              <Box {...secTitleNext}>
                <Text
                  {...secDescription}
                  content={group.name}
                />
              </Box>
              <Box {...row}>
                {Data.portfolioJson.TECHNOLOGIES.map((item, index) => {
                  if (item.group !== group.key) return <React.Fragment />;

                  return (
                    <ClientsImage key={`client-${index}`}>
                      <Image
                        src={item.image.publicURL}
                        alt={item.title}
                        title={item.title}
                      />
                    </ClientsImage>
                  )
                })}
              </Box>
            </div>
          ))
        }
      </Container>
    </Box>
  );
};

ClientsSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
};

ClientsSection.defaultProps = {
  sectionWrapper: {
    pt: ['40px', '60px', '80px', '80px', '80px'],
    pb: ['60px', '80px', '100px', '130px', '130px'],
  },
  secTitleWrapper: {
    mb: '10px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#030b16',
    lineHeight: '1.34',
    mb: ['20px'],
    textAlign: 'center',
  },
  secTitleNext: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#030b16',
    lineHeight: '1.34',
    mt: ['20px'],
    mb: ['10px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: '16px',
    fontWeight: '700',
    color: '#030b16',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'left',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default ClientsSection;
