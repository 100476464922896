import React from 'react';
import { SocialProfileWrapper, SocialProfileItem } from './socialProfile.style';
import { SocialIcon } from 'react-social-icons';

const SocialProfile = ({ items, className }) => {
  const addAllClasses = ['social_profiles'];

  if (className) {
    addAllClasses.push(className);
  }

  return (
    <SocialProfileWrapper className={addAllClasses.join(' ')}>
      {items.map((item, index) => (
        <SocialProfileItem
          key={`social-item-${index}`}
          className="social_profile_item"
        >
          <SocialIcon url={item.url} target="_blank"/>
        </SocialProfileItem>
      ))}
    </SocialProfileWrapper>
  );
};

export default SocialProfile;
